import { initGraphQLTada } from "gql.tada";
import type { introspection } from "./graphql-env";

export { readFragment } from "gql.tada";
export type { FragmentOf, ResultOf, VariablesOf } from "gql.tada";
export const graphql = initGraphQLTada<{
  introspection: introspection;
  scalars: {
    DateTime: Date;
    JSON: any;
  };
}>();
